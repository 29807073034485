var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "new-lists" } },
    [
      _c(
        "a-tabs",
        {
          attrs: { defaultActiveKey: "1", size: "large" },
          on: { change: _vm.callback }
        },
        [
          _vm.tagList
            ? _c(
                "a-tab-pane",
                { key: "1", attrs: { tab: "最新主题" } },
                _vm._l(_vm.tagList.zxtz, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "list-item",
                      on: {
                        click: function($event) {
                          return _vm.btnTag(item.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.username))
                      ]),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(+item.create_time)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.tagList
            ? _c(
                "a-tab-pane",
                { key: "2", attrs: { tab: "最热主题", forceRender: "" } },
                _vm._l(_vm.tagList.zrtz, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "list-item",
                      on: {
                        click: function($event) {
                          return _vm.btnTag(item.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.username))
                      ]),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(+item.create_time)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.tagList
            ? _c(
                "a-tab-pane",
                { key: "3", attrs: { tab: "点赞最多", forceRender: "" } },
                _vm._l(_vm.tagList.xftz, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "list-item",
                      on: {
                        click: function($event) {
                          return _vm.btnTag(item.id)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("div", { staticClass: "name" }, [
                        _vm._v(_vm._s(item.username))
                      ]),
                      _c("div", { staticClass: "date" }, [
                        _vm._v(
                          _vm._s(
                            _vm
                              .$moment(+item.create_time)
                              .format("YYYY-MM-DD HH:mm")
                          )
                        )
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }