<template>
  <div id="new-lists">
    <a-tabs defaultActiveKey="1" @change="callback" size="large">
      <a-tab-pane tab="最新主题" key="1" v-if="tagList">
        <div class="list-item" v-for="item in tagList.zxtz" :key="item.id" @click="btnTag(item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="name">{{ item.username }}</div>
          <div class="date">{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
        </div>
      </a-tab-pane>
      <a-tab-pane tab="最热主题" key="2" v-if="tagList" forceRender>
        <div class="list-item" v-for="item in tagList.zrtz" :key="item.id" @click="btnTag(item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="name">{{ item.username }}</div>
          <div class="date">{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
        </div>
      </a-tab-pane>
      <a-tab-pane tab="点赞最多" key="3" v-if="tagList" forceRender>
        <div class="list-item" v-for="item in tagList.xftz" :key="item.id" @click="btnTag(item.id)">
          <div class="title">{{ item.title }}</div>
          <div class="name">{{ item.username }}</div>
          <div class="date">{{ $moment(+item.create_time).format('YYYY-MM-DD HH:mm') }}</div>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: '',
      tagList: undefined,
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getTag();
  },
  methods: {
    callback(key) {
      // console.log(key);
    },
    getTag() {
      if (this.id == 35) {
        this.id = '';
      }
      return this.$request(Apis.forum.ZRZTS, { data: { cate_id: +this.id } })
        .then(data => {
          this.tagList = data.data;
        })
        .catch(e => {})
        .finally(() => {});
    },
    btnTag(id) {
      this.$router.push('/forum/details/' + id);
    },
  },
};
</script>
<style lang="less">
#new-lists {
  width: 100%;
  .ant-tabs-bar {
    border: 0;
    .ant-tabs-nav-animated {
      width: 100%;

      .ant-tabs-tab {
        color: #ccc;
        padding: 0;
        font-weight: 600;
        font-size: 18px;
      }
      .ant-tabs-tab-active {
        color: #ffcc00 !important;
        font-weight: 600px !important;
      }
      .ant-tabs-ink-bar {
        background: none;
      }
    }
  }
  .ant-tabs-tabpane {
    .list-item {
      display: flex;
      justify-content: flex-start;
      color: #ccc;
      border-bottom: 1px solid;
      padding-bottom: 5px;
      margin-bottom: 20px;
      cursor: pointer;
      .title {
        width: 60%;
        padding-right: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .name,
      .date {
        width: 20%;
        padding-right: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .date {
        text-align: right;
        padding-right: 0;
      }
    }
  }
}
</style>